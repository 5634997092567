import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";

import { Query } from "@graphql-types";
import {
  assets,
  colors,
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
} from "@util/constants";
import { Container, H2, VerticalSeparator } from "@util/standard";
import { BlocksContent, Image } from "@sub";
import { FluidObject } from "gatsby-image";

const Wrapper = styled(Container)`
  flex-direction: column;
`;

const ContentContainer = styled(Container)`
  width: 90%;
  background-color: ${colors.lightBlue};
  margin: 150px 0;
  overflow: initial;
  height: 745px;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin: 50px 0;
    flex-direction: column-reverse;
    height: auto;
    width: auto;
    padding: 50px;
    overflow: hidden;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 0;
  }
`;

const Content = styled(Container)`
  margin: auto;
  width: auto;
`;

const BlockContainer = styled(Container)`
  width: 400px;
  flex-direction: column;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: auto;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 75%;
  }
`;

const Img = styled.img`
  width: 60px;
  height: 60px;
  margin: 0 20px 0 0;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 30px;
    height: 30px;
  }
`;

const StyledContainer = styled(Container)`
  width: 90%;
  margin: auto;
`;

const ImageContainer = styled(Container)`
  margin-top: 65px;
  margin-left: 90px;
  height: 785px;
  width: 490px;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin-top: 35px;
    margin-left: 0px;
    height: 685px;
    width: auto;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    height: 385px;
  }
`;

const TelecomInfo = () => {
  const { sanityRegulatedSectorsPage }: Query = useStaticQuery(graphql`
    {
      sanityRegulatedSectorsPage {
        telecomInfo {
          title
          subtitle
          description {
            _rawColumnContent(resolveReferences: { maxDepth: 4 })
          }
          image {
            asset {
              fluid {
                srcWebp
                srcSetWebp
                ...GatsbySanityImageFluid
              }
              originalFilename
            }
          }
        }
      }
    }
  `);

  return (
    <Wrapper>
      <StyledContainer>
        <ContentContainer>
          <ImageContainer>
            <Image
              fluid={
                sanityRegulatedSectorsPage?.telecomInfo?.image?.asset
                  ?.fluid as FluidObject
              }
              isBackground
              backgroundStyle={{
                width: "100%",
              }}
            />
          </ImageContainer>
          <Content>
            <Img alt="telecom-icon" src={assets.telecom} />
            <VerticalSeparator dimensions={{ height: "80px", width: "25px" }} />
            <BlockContainer>
              <H2 margin="0">
                {sanityRegulatedSectorsPage?.telecomInfo?.title}
              </H2>
              <H2 fontWeight="normal" margin="0">
                {sanityRegulatedSectorsPage?.telecomInfo?.subtitle}
              </H2>
              <BlocksContent
                blocks={
                  sanityRegulatedSectorsPage?.telecomInfo?.description
                    ?._rawColumnContent
                }
              />
            </BlockContainer>
          </Content>
        </ContentContainer>
      </StyledContainer>
    </Wrapper>
  );
};

export default TelecomInfo;

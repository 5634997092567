import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";

import { Maybe, Query, SanityRegulations } from "@graphql-types";
import { Container } from "@util/standard";
import { colors, TABLET_BREAKPOINT } from "@util/constants";
import { BlocksContent } from "@components/shared/sub";

const Wrapper = styled(Container)`
  flex-direction: column;
  width: 100%;
  margin: 0 auto 150px auto;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin: 0 auto 50px auto;
  }
`;

const PolicyContainer = styled(Container)<{ background?: boolean }>`
  background-color: ${(props) =>
    props.background ? colors.lightBlue : colors.white};
`;

const PolicyWrapper = styled(Container)`
  width: 80%;
  margin: 150px auto;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin: 50px auto;
    flex-direction: column;
  }
`;

const PolicyContent = styled(Container)`
  flex-direction: column;
  width: 100%;
`;

const Title = styled.p<{ bold?: boolean }>`
  font-size: 27px;
  font-family: "Playfair Display";
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
  margin: 0;
`;

const PolicyTitle = styled.p`
  font-size: 25px;
  font-family: "Source Sans Pro";
`;

const TitleColumn = styled(Container)`
  flex-direction: column;
  width: 340px;
  margin-right: 30px;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
  }
`;

const ContentContainer = styled(Container)`
  flex-direction: column;
  border-left: 1.5px solid ${colors.darkBlue};
  padding-left: 40px;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    border-left: none;
    padding-left: 0;
  }
`;

const Policies = () => {
  const { sanityRegulatedSectorsPage }: Query = useStaticQuery(graphql`
    {
      sanityRegulatedSectorsPage {
        regulations {
          policiesTitle
          policiesSubtitle
          licenses {
            title
            _key
            description {
              _rawColumnContent(resolveReferences: { maxDepth: 4 })
            }
          }
          _key
        }
      }
    }
  `);

  const Policy = ({
    props,
    index,
  }: {
    props: Maybe<SanityRegulations>;
    index: number;
  }) => {
    if (props == null) {
      return null;
    }

    return (
      <PolicyContainer background={Math.abs(index % 2) == 1}>
        <PolicyWrapper>
          <TitleColumn>
            <Title bold>{props.policiesTitle}</Title>
            <Title>{props.policiesSubtitle}</Title>
          </TitleColumn>

          <PolicyContent>
            {props.licenses?.map((license) => {
              return (
                <ContentContainer key={license?._key}>
                  <PolicyTitle>{license?.title}</PolicyTitle>
                  <BlocksContent
                    blocks={license?.description?._rawColumnContent}
                  />
                </ContentContainer>
              );
            })}
          </PolicyContent>
        </PolicyWrapper>
      </PolicyContainer>
    );
  };

  return (
    <Wrapper>
      {sanityRegulatedSectorsPage?.regulations?.map((regulation, index) => {
        return (
          <Policy key={regulation?._key} props={regulation} index={index} />
        );
      })}
    </Wrapper>
  );
};

export default Policies;

import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import Hero from "@components/shared/hero";
import { Query } from "@graphql-types";

const RegulatedSectorsHero = () => {
  const { sanityRegulatedSectorsPage }: Query = useStaticQuery(graphql`
    {
      sanityRegulatedSectorsPage {
        hero {
          content {
            _rawRichTextContent
          }
          links {
            url
            newwindow
            linktext
            internallink
            _key
          }
        }
      }
    }
  `);

  return <Hero {...sanityRegulatedSectorsPage?.hero} height="50vh" />;
};

export default RegulatedSectorsHero;
